import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// ContactDetailsHeader.js
const ContactDetailsHeader = ({ contact, setContact }) => {
  return (
    <div className="w-full bg-white shadow-xl rounded-xl p-8 mb-8 border border-gray-100">
      <div className="grid grid-cols-3 gap-8">
        {/* Personal Info Column */}
        <div className="border-r border-gray-200 pr-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
            <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            Personal Information
          </h3>
          <div className="space-y-3">
            <a
              href={`https://bison-point-capital.lightning.force.com/lightning/r/Contact/${contact.salesforce_id}/view`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl font-bold text-blue-600 hover:text-blue-800 transition-colors duration-200"
            >
              {`${contact.first_name} ${contact.last_name}`}
            </a>
           {/* Address Section */}
           <div className="pt-3 border-t border-gray-100">
              <div className="flex items-center mb-2">
                <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span className="text-sm text-gray-500">Address</span>
              </div>
              {contact.contact_addresses?.[0] ? (
                <div className="text-gray-600 space-y-1">
                  <p>{contact.contact_addresses[0].address_number} {contact.contact_addresses[0].address_street}</p>
                  <p>{`${contact.contact_addresses[0].address_city}, ${contact.contact_addresses[0].address_state} ${contact.contact_addresses[0].address_zip}`}</p>
                </div>
              ) : (
                <p className="text-gray-400 italic text-sm">No address provided</p>
              )}
            </div>
          </div>
        </div>

        {/* Phone Numbers Column */}
        <div className="grid grid-rows-[auto_1fr_auto] h-full">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
            <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            Phone Numbers
          </h3>

          {/* Scrollable list container */}
          <div className="overflow-y-auto max-h-[300px] mb-4">
            <ul className="space-y-3">
              {contact.contact_phones?.map(phone => (
                <li key={phone.id} className="flex items-center justify-between group">
                  <span className="text-gray-600 hover:text-blue-600 transition-colors duration-200 cursor-pointer">
                    {formatPhoneNumber(phone.number)}
                  </span>
                  <VerificationButtons
                    contact={contact}
                    itemId={phone.id}
                    itemType="phone"
                    verifiedStatus={phone.verified}
                    onInvalid={(id) => {
                      setContact(prev => ({
                        ...prev,
                        contact_phones: prev.contact_phones.filter(p => p.id !== id)
                      }));
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>

          {/* Fixed input field at bottom */}
          <div className="mt-auto">
            <AddContactField
              contact={contact}
              type="phone"
              onAdd={(contact_phone) => {
                setContact(prev => ({
                  ...prev,
                  contact_phones: [...prev.contact_phones, contact_phone]
                }));
              }}
            />
          </div>
        </div>

        {/* Emails Column */}
        <div className="grid grid-rows-[auto_1fr_auto] h-full">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
            <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            Email Addresses
          </h3>

          <div className="overflow-y-auto max-h-[300px] mb-4">
            <ul className="space-y-3">
              {contact.contact_emails?.map(email => (
                <li key={email.id} className="flex items-center justify-between group">
                  <span className="text-gray-600 hover:text-blue-600 transition-colors duration-200 cursor-pointer">
                    {email.email}
                  </span>
                  <VerificationButtons
                    contact={contact}
                    itemId={email.id}
                    itemType="email"
                    verifiedStatus={email.verified}
                    onInvalid={(id) => {
                      setContact(prev => ({
                        ...prev,
                        contact_emails: prev.contact_emails.filter(e => e.id !== id)
                      }));
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-auto">
            <AddContactField
              contact={contact}
              type="email"
              onAdd={(contact_email) => {
                setContact(prev => ({
                  ...prev,
                  contact_emails: [...prev.contact_emails, contact_email]
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AddAccountModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    accountName: '',
    address: ''
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-50/75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h3 className="text-lg font-semibold mb-4">Add New Account</h3>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData);
          setFormData({ accountName: '', address: '' });
        }}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Account Name*
              </label>
              <input
                autoComplete="off"
                type="text"
                required
                value={formData.accountName}
                onChange={(e) => setFormData(prev => ({ ...prev, accountName: e.target.value }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address (optional)
              </label>
              <input
                autoComplete="off"
                type="text"
                value={formData.address}
                onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
              >
                Add Account
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

// AddPropertyModal component
const AddPropertyModal = ({ isOpen, onClose, onSubmit, accountId }) => {
  const [formData, setFormData] = useState({
    address: '',
    propertyType: '',
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-50/75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h3 className="text-lg font-semibold mb-4">Add New Property</h3>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(accountId, formData);
          setFormData({ address: '', propertyType: '' });
        }}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address*
              </label>
              <input
                type="text"
                required
                autoComplete="off"
                value={formData.address}
                onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Property Type*
              </label>
              <select
                required
                value={formData.propertyType}
                onChange={(e) => setFormData(prev => ({ ...prev, propertyType: e.target.value }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select type...</option>
                <option value="commercial">Commercial</option>
                <option value="residential">Residential</option>
                <option value="industrial">Industrial</option>
                <option value="land">Land</option>
              </select>
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
              >
                Add Property
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

// AccountsList.js
const AccountsList = ({ accounts, onSelectProperty, selectedPropertyId, setAccounts, contactId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [propertyModal, setPropertyModal] = useState({ isOpen: false, accountId: null });

  const handleAddAccount = async (formData) => {
    debugger
    const data = {
      contact_id: contactId,
      account_name: formData.accountName,
      address: formData.address,
    }
    try {
      const response = await fetch('/api/accounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const newAccountData = await response.json();
        setAccounts(prev => [...prev, newAccountData]);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error('Error adding new account:', error);
    }
  };

  const handleAddProperty = async (accountId, formData) => {
    try {
      const response = await fetch('/api/properties', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: formData.address,
          property_type: formData.propertyType,
          account_id: accountId
        }),
      });

      if (response.ok) {
        const newPropertyData = await response.json();
        console.log(newPropertyData);
        setAccounts(prev => prev.map(account => {
          if (account.id === accountId) {
            return {
              ...account,
              properties: [...account.properties, newPropertyData]
            };
          }
          return account;
        }));
        setPropertyModal({ isOpen: false, accountId: null });
      }
    } catch (error) {
      console.error('Error adding new property:', error);
    }
  };

  const handleInvalidateAccount = async (accountId) => {
    try {
      const response = await fetch(`/api/accounts/${accountId}/invalidate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        // Remove account from state
        setAccounts(prevAccounts =>
          prevAccounts.filter(account => account.id !== accountId)
        );
      }
    } catch (error) {
      console.error('Error invalidating account:', error);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const sourceAccountId = result.source.droppableId;
    const destinationAccountId = result.destination.droppableId;
    const propertyId = result.draggableId;

    if (sourceAccountId === destinationAccountId) return;

    // Store original state for rollback
    const originalAccounts = [...accounts];

    // Optimistically update UI
    setAccounts(prevAccounts => {
      const newAccounts = prevAccounts.map(account => {
        if (account.id.toString() === sourceAccountId) {
          // Remove from source account
          return {
            ...account,
            properties: account.properties.filter(p => p.id.toString() !== propertyId)
          };
        }
        if (account.id.toString() === destinationAccountId) {
          // Add to destination account
          const propertyToMove = originalAccounts
            .find(a => a.id.toString() === sourceAccountId)
            .properties.find(p => p.id.toString() === propertyId);
          return {
            ...account,
            properties: [...account.properties, propertyToMove]
          };
        }
        return account;
      });
      return newAccounts;
    });

    try {
      const response = await fetch(`/api/properties/${propertyId}/update_account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          existing_account_id: sourceAccountId,
          new_account_id: destinationAccountId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update property account');
      }
    } catch (error) {
      console.error('Error updating property account:', error);
      // Rollback on error
      setAccounts(originalAccounts);
    }
  };

  return (
    <div className="bg-white shadow-xl rounded-xl p-6 border border-gray-100">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-semibold text-gray-800 flex items-center">
          <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          Accounts & Properties
        </h3>
        <button
          onClick={() => setIsModalOpen(true)}
          className="p-1.5 rounded-full bg-green-500 hover:bg-green-600 text-white transition-colors duration-200"
          title="Add New Account"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>

      <AddAccountModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddAccount}
      />
      <AddPropertyModal
        isOpen={propertyModal.isOpen}
        accountId={propertyModal.accountId}
        onClose={() => setPropertyModal({ isOpen: false, accountId: null })}
        onSubmit={handleAddProperty}
      />

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="space-y-6">
          {accounts?.map(account => (
            <div key={account.id} className="border border-gray-100 rounded-lg p-4 transition-shadow duration-200">
             <div className="flex justify-between items-center mb-3">
                <h4 className="font-medium text-gray-800 flex items-center">
                  <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <a
                    href={`https://bison-point-capital.lightning.force.com/lightning/r/Account/${account.salesforce_id}/view`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {account.name}
                  </a>
                </h4>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setPropertyModal({ isOpen: true, accountId: account.id })}
                    className="p-1.5 rounded-md bg-green-500 hover:bg-green-600 text-white transition-colors duration-200"
                    title="Add New Property"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                    </svg>
                  </button>
                  {account.properties?.length === 0 && (
                    <button
                      onClick={() => handleInvalidateAccount(account.id)}
                      className="p-1.5 rounded-md text-red-500 hover:bg-red-50 transition-colors duration-200"
                      title="Invalidate Account"
                    >
                      ❌
                    </button>
                  )}
                </div>
              </div>
              <Droppable droppableId={account.id.toString()}>
                {(provided) => (
                  <ul
                    className="pl-6 space-y-2"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {account.properties?.map((property, index) => (
                      <Draggable
                        key={property.id}
                        draggableId={property.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`
                              cursor-move p-3 rounded-lg transition-all duration-200 flex items-center
                              ${snapshot.isDragging ? 'bg-blue-50 shadow-lg' : ''}
                              ${selectedPropertyId === property.id
                                ? 'bg-blue-50 text-blue-700 border border-blue-200'
                                : 'hover:bg-gray-50 text-gray-600 hover:text-gray-900'
                              }
                            `}
                            onClick={() => onSelectProperty(property, account)}
                          >
                            <svg className="w-4 h-4 mr-2 text-current" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                            </svg>
                            {property.address}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

// PropertyDetails.js
const PropertyDetails = ({ property, contact, account }) => {
  if (!property) {
    return (
      <div className="bg-white shadow-lg rounded-lg p-6">
        <p className="text-gray-500">Select a property to view details</p>
      </div>
    );
  }

  return (
    <div>
      <NoteField
        accountId={account?.id}
        propertyId={property.id}
        contactId={contact.id}
        initialNote={property.note?.content}
        onSave={(note) => {
          // Handle note update if needed
        }}
      />
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div className="text-xl font-semibold mb-4">
          <a
            className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
            href={`https://bison-point-capital.lightning.force.com/lightning/r/Property__c/${property.salesforce_id}/view`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {property.address}
          </a>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <p className="text-gray-600">Size: {property.total_building_sqft} sqft</p>
            <p className="text-gray-600">Lot Size: {property.lot_size_sqft} sqft</p>
            <p className="text-gray-600">Year Built: {property.year_built}</p>
          </div>
          <div>
            <p className="text-gray-600">Building Count: {property.building_count}</p>
            <p className="text-gray-600">Data Source: {property.data_source}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main ContactDetails component
const ContactDetails = ({ contactId }) => {
  const [contact, setContact] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    const scriptTag = document.getElementById("contact_id");
    if (scriptTag) {
      contactId = scriptTag.textContent.trim();
    }

    const fetchContact = async () => {
      try {
        const response = await fetch(`/api/contacts/${contactId}`);
        const data = await response.json();
        setContact(data);
        setAccounts(data.accounts)
        setSelectedProperty(data.accounts[0]?.properties[0] || null);
        setSelectedAccount(data.accounts[0]);
      } catch (error) {
        console.error('Error fetching contact:', error);
      }
    };

    fetchContact();
  }, [contactId]);

  if (!contact) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header Section */}
      <ContactDetailsHeader contact={contact} setContact={setContact} />

      {/* Main Content */}
      <div className="flex gap-6">
        {/* Left Sidebar */}
        <div className="w-1/3">
          <AccountsList
            contactId={contact?.id}
            accounts={accounts}
            setAccounts={setAccounts}
            onSelectProperty={(property, account) => {
              setSelectedProperty(property);
              setSelectedAccount(account);
            }}
            selectedPropertyId={selectedProperty?.id}
          />
        </div>

        {/* Right Content */}
        <div className="w-2/3">
          <PropertyDetails property={selectedProperty} contact={contact} account={selectedAccount} />
        </div>
      </div>
    </div>
  );
};

// VerificationButtons component
const VerificationButtons = ({ itemId, itemType, onInvalid, contact, verifiedStatus }) => {
  const [isVerified, setIsVerified] = useState(verifiedStatus == "verified");

  const handleVerify = async (verified) => {
    console.log("Button pressed", itemId, itemType, verified);
    try {
      const response = await fetch(`/api/contacts/${contact.id}/update_verification_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          item_id: itemId,
          item_type: itemType,
          verified: verified,
        }),
      });

      if (response.ok) {
        setIsVerified(!!verified);
      }
    } catch (error) {
      console.error('Error updating verification status:', error);
    }
  };

  const handleInvalid = async (verified) => {
    console.log("Button pressed", itemId, itemType, verified);
    try {
      const response = await fetch(`/api/contacts/${contact.id}/update_verification_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          item_id: itemId,
          item_type: itemType,
          invalid: true
        }),
      });

      if (response.ok) {
        onInvalid?.(itemId, itemType);
      }
    } catch (error) {
      console.error('Error updating verification status:', error);
    }
  };

  return (
    <div className="flex space-x-2">
      <button
        onClick={() => handleVerify(!isVerified)}
        className={`p-1.5 rounded-md transition-colors duration-200 ${
          isVerified
            ? 'bg-green-100 text-green-600'
            : 'hover:bg-gray-100 text-gray-400'
        }`}
      >
        👍
      </button>
      <button
        onClick={() => handleInvalid(false)}
        className={'p-1.5 rounded-md transition-colors duration-200 hover:bg-gray-100 text-gray-400'}
      >
        ❌
      </button>
    </div>
  );
};

const AddContactField = ({ contact, type, onAdd }) => {
  const [value, setValue] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!value.trim()) return;

    try {
      const response = await fetch(`/api/contacts/${contact.id}/add_contact_information`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type, value }),
      });

      if (response.ok) {
        const data = await response.json();
        onAdd(JSON.parse(data["contact_information"]));
        setValue(''); // Reset input
      }
    } catch (error) {
      console.error(`Error adding ${type}:`, error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4 flex items-center space-x-2">
      <input
        type={type === 'email' ? 'email' : 'tel'}
        autoComplete="off"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={`Add new ${type}...`}
        className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
      />
      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Add
      </button>
    </form>
  );
};

const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check for different lengths to handle various formats
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
    return `+1 (${cleaned.slice(1,4)}) ${cleaned.slice(4,7)}-${cleaned.slice(7)}`;
  }

  // Return original if format unknown
  return phoneNumber;
};

const EditableField = ({ value, onSave, placeholder }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value || '');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSave(editValue);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <form onSubmit={handleSubmit} className="flex items-center space-x-2">
        <input
          type="text"
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          className="px-2 py-1 border border-blue-300 rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          autoFocus
        />
        <button
          type="submit"
          className="text-sm text-blue-500 hover:text-blue-700"
        >
          Save
        </button>
        <button
          type="button"
          onClick={() => setIsEditing(false)}
          className="text-sm text-gray-500 hover:text-gray-700"
        >
          Cancel
        </button>
      </form>
    );
  }

  return (
    <div
      onClick={() => setIsEditing(true)}
      className="cursor-pointer hover:bg-gray-50 rounded px-2 py-1 -mx-2"
    >
      {value ? (
        <span>{value}</span>
      ) : (
        <span className="text-gray-400 italic">{placeholder}</span>
      )}
    </div>
  );
};

const NoteField = ({ propertyId, contactId, accountId, initialNote, onSave }) => {
  const [note, setNote] = useState(initialNote || '');
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [noteableType, setNoteableType] = useState('Property');

  const getNoteableId = () => {
    switch (noteableType) {
      case 'Property':
        return propertyId;
      case 'Contact':
        return contactId;
      case 'Account':
        return accountId;
      default:
        return propertyId;
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await fetch('/api/notes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: title,
          content: note,
          noteable_type: noteableType,
          noteable_id: getNoteableId()
        }),
      });

      if (response.ok) {
        const data = await response.json();
        onSave?.(data);
        setNote('');
        setTitle('');
      }
    } catch (error) {
      console.error('Error saving note:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-800 flex items-center">
            <svg className="w-5 h-5 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            Notes
          </h3>
          <div className="flex items-center space-x-4">
            <select
              value={noteableType}
              onChange={(e) => setNoteableType(e.target.value)}
              className="px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm"
            >
              <option value="Property">Property Note</option>
              <option value="Contact">Contact Note</option>
              <option value="Account">Account Note</option>
            </select>
            <button
              onClick={handleSave}
              disabled={isSaving}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:opacity-50"
            >
              {isSaving ? 'Saving...' : 'Save Note'}
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Note title..."
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder={`Add notes about this ${noteableType.toLowerCase()}...`}
            className="w-full h-32 p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 resize-none"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
